<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Inquiry Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="table"
          name="Inquiry.xls"
          :fields="fields"
          style="height: 30px; margin-top: 9px; margin-right: 10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <input
          slot="activator"
          style="color: black; margin-top: 7px"
          v-model="phone"
          class="search"
          placeholder="Enter AccountNumber or Id"
        />
        <v-btn
          class="success searchbtn"
          style="height: 34px; margin-left: 10px"
          @click="fetchstatement"
          >Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="table"
        hide-actions
        :search="search"
        :loading="loading"
      >
        <v-progress-linear
          slot="progress"
          color="blue"
          indeterminate
        ></v-progress-linear>
        <template slot="items" slot-scope="props">
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.orderno }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.service }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.mobile }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.amount }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            <v-btn
              small
              :class="checkstatus(props.item.status)"
              style="width: 100%"
              >{{ props.item.status }}</v-btn
            >
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.tid }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.type }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.openbalance }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.closebalance }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.com }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{ props.item.surcharge }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            {{
              new Date(parseInt(props.item.time))
                | moment("DD-MM-YYYY, h:mm:ss a")
            }}
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            <v-btn
              small
              style="width: 100%"
              v-if="props.item.status.toLowerCase() != 'fail'"
              @click="openremark(props.item)"
              class="warning"
              >Complaint</v-btn
            >
          </td>
          <td :style="{ background: getbackground(props.item.status) }">
            <v-btn small style="width: 100%" @click="print(props.item)" class="primary">Print</v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
    <Complaint v-if="complaint" :id="tid" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";

import Complaint from "../components/Complaint.vue";

export default {
  components: {
    Progress,
    Preview,
    Message,
    Complaint,
  },
  data() {
    return {
      way: "prepaid",
      loading: false,
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      search: "",
      type: "BP",
      phone: "",
      tid: "",
      pagination: {},
      complaint: false,
      fields: {
        "Recharge ID": "orderno",
        Service: "service",
        MobileNo: "mobile",
        Amount: "amount",
        Status: "status",
        "Transaction ID": "tid",
        Type: "type",
        "Opening Balance": "openbalance",
        "Closing Balance": "closebalance",
        Commission: "com",
        Surcharge: "surcharge",
        "Recharge Time": "time",
        Reason:"Reason",
      },
      headers: [
        {
          text: "Transaction ID",
          value: "orderno",
          align: "left",
          sortable: false,
        },
        { text: "TxnMode", value: "service" },
        { text: "AccountNumber", value: "mobile" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Transaction ID", value: "tid" },
        { text: "Type", value: "type" },
        { text: "Opening Balance", value: "openbalance" },
        { text: "Closing Balance", value: "closebalance" },
        { text: "Commission", value: "com" },
        { text: "Surcharge", value: "surcharge" },
        { text: "Recharge Time", value: "time" },
        { text: "Complain", value: "action", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      report: [],
      table: [],
    };
  },
  methods: {
    checkstatus(status) {
      status = status.toLowerCase();
      if (status == "success") return "success";
      if (status == "pending") return "grey";
      if (status == "in process") return "warning";
      if (status == "fail") return "error";
      return "primary";
    },
    getbackground(status) {
      status = status.toLowerCase();
      if (status == "success") return "#ffff";
      if (status == "pending") return "#B0BEC5";
      if (status == "in process") return "#FFF59D";
      if (status == "fail") return "#EF9A9A";
      return "#90CAF9";
    },
    checkrbtn(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return "Unknown";
    },
    openremark(item) {
      let vm = this;
      vm.tid = item.tid;
      if (item.tid == null) return;
      vm.complaint = true;
    },
    handleresize() {
      let vm = this;
      let width = window.innerWidth - 330 - 293 + "px";
      vm.$store.commit("setrwidth", width);
    },
    showtable() {
      let vm = this;
      vm.table = [];
      for (let i = 0; i < vm.report.length; i++) {
        let doc = vm.report[i];
        let data = {
          orderno: doc.RechargeId,
          service: doc.ServiceName,
          mobile: doc.MobileNo,
          status: doc.Status,
          type: doc.RechargeType,
          com: doc.Commission,
          tid: doc.OperatorId,
          amount: doc.Amount,
          openbalance: doc.OpeningBal,
          closebalance: doc.ClosingBal,
          time: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          surcharge: doc.Commission,
          Reason : doc.Reason,
        };
        vm.table.push(data);
      }
      vm.loading = false;
    },
    fetchstatement() {
      let vm = this;
      if (vm.phone == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Please Enter Account Number",
          type: "warn",
        });
    
      if (vm.user.length != 0) {
        vm.loading = true;
        let doc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          SearchMobileNo: vm.phone,
        };
        let url = vm.$root.loginType === "dmr" ? "InquiryDMR" : "Inquiry";
        vm.$store
          .dispatch("axios", {
            url: url,
            data: doc,
          })
          .then(function (res) {
            vm.report = [];
            if (res.data.Data != null) {
              vm.report = res.data.Data;
              vm.showtable();
            }
            vm.loading = false;
          })
          .catch(function (error) {
            vm.$store.dispatch("notify", {
              vm: vm,
              message: error,
              type: "warn",
            });
          });
      }
    },

    print(item) {
      debugger;
      let vm = this;
      var status = item.status;
      if (!vm.change) {
        status = vm.checkrbtn(item.status);
      }
      var date = vm.$moment(parseInt(item.time)).format("DD-MM-YYYY, h:mm a");
      var tid = item.tid;
      if (tid == null && tid == undefined) {
        tid = "-";
      }
      let splitArray = item.Reason.split(',');        
      let resdetail =  splitArray[0];
      let Acresdetail =  resdetail.split(':');    
      let Acountresdetail = Acresdetail[1].split('-'); 
      let Name =   Acountresdetail[0].split('$').join(' ');
      let ifsc =   Acountresdetail[2];
      let bankname =    Acountresdetail[3].split('$').join(' ');
  
      var mywindow = window.open("");
      var head =
        "<html><head><title>Print</title><style>.mainwidth{width:400px;min-height:650px;background:white;margin:0 auto;border-radius:5px;}.header{width:398px;margin:1px;float:left;height:30px;border:0px;background:transparent;font-size:1em;background:lightseagreen;color:white;}.table{padding:10px;width:100%;}.table tr td{padding-bottom:5px;padding-top:5px;border-bottom:1px solid lightgrey;}</style>";
      mywindow.document.write(head);
      mywindow.document.write("</head><body >");
      mywindow.document.write(
         '<body><div style="border:1px solid lightgrey" class="mainwidth"><button class="header">Transaction Receipt</button>'
      );
      mywindow.document.write('<table class="table">');
      mywindow.document.write(
        "<tr><td>Transaction ID</td><td>" + item.orderno + "</td></tr>"
      );
      mywindow.document.write("<tr><td>RRN Number</td><td>" + tid + "</td></tr>");
      mywindow.document.write("<tr><td>Benificiary Name</td><td>" + Name + "</td></tr>");
      mywindow.document.write("<tr><td>Bank Name</td><td>" + bankname + "</td></tr>");
      mywindow.document.write("<tr><td>IFSC Code</td><td>" + ifsc + "</td></tr>");
      mywindow.document.write("<tr><td>Account Number</td><td>" +  item.mobile + "</td></tr>");
      mywindow.document.write(
        "<tr><td>Transaction Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Service Charge</td><td>" + item.surcharge + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Total Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write("<tr><td>Date</td><td>" + date + "</td></tr>");
      // mywindow.document.write(
      //   "<tr><td>Dealer Name</td><td>" + vm.user.UserName + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Operator Name</td><td>" + item.service + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Reference No</td><td>" + item.orderno + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Amount</td><td>" + item.amount + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Mobile No</td><td>" + item.mobile + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Status</td><td>" + status + "</td></tr>"
      // );
      // mywindow.document.write("</table>");
      // mywindow.document.write(
      //   '<button class="header">Retailer Information</button>'
      // );
      // mywindow.document.write('<table class="table">');
      // mywindow.document.write(
      //   "<tr><td>Name</td><td>" +
      //     vm.getprofile.FirstName +
      //     " " +
      //     vm.getprofile.LastName +
      //     "</td></tr>"
      // );
      // mywindow.document.write(
      //   '<tr><td style="width:100px">Mobile No</td><td>' +
      //     vm.getprofile.MobileNo +
      //     "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Email ID</td><td> " + vm.getprofile.Email + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Address</td><td>" + vm.getprofile.Address + "</td></tr>"
      // );
      // mywindow.document.write("</table>");
      mywindow.document.write("</div>");
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      setTimeout(print, 1000);
      function print() {
        mywindow.print();
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style scoped>
.search {
  width: 200px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
